jQuery(document).ready(function ($) {
	var modalOpen = $('.open-modal');
	var modalClose = $('.close-modal');
	$(document).on('click', '.open-modal', function (e) {
		e.preventDefault();

		var modalLink = $(this);

		var modalID = $(this).attr('data-modal');

		var modalFocusable = $('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]', '.modal#' + modalID);
		var modalFocusableFirst = modalFocusable.eq(1);
		var modalFocusableLast = modalFocusable.last();
		modalFocusableFirst.addClass('first focus');
		modalFocusableLast.addClass('last focus');

		if (typeof modalID !== typeof undefined && modalID !== false) {
			$('html, body').toggleClass('modal-open');
			$('.modal-overlay').toggleClass('show-modal');
			$('.modal#' + modalID).toggleClass('show-modal');
		} else {
			var newModalContent = modalLink.next('.modal-content').html();
			var newModal = $('<div id="newModal" class="modal" role="dialog"><span class="close-modal" aria-label="Close Modal" tabindex="0">×</span>' + newModalContent + '</div>');
			$(document.body).append(newModal);
			$('html, body').toggleClass('modal-open');
			$('.modal-overlay').toggleClass('show-modal');
			$('.modal#newModal').toggleClass('show-modal');
		}
	});
	$('.modal-overlay').click(function () {
		$('html, body').removeClass('modal-open');
		$(this).removeClass('show-modal');
		$('.modal').removeClass('show-modal');
	});
	$('.modal *:not(.close-modal)').click(function (e) {
		e.stopPropagation();
	});
	$(document).on('click', '.close-modal', function () {
		$('html, body').removeClass('modal-open');
		if ($(this).closest('.modal').is('#newModal')) {
			$('.modal#newModal').remove();
			$('.modal-overlay').toggleClass('show-modal');
		} else {
			$(this).closest('.modal').toggleClass('show-modal');
			$('.modal-overlay').toggleClass('show-modal');
		}
	});
});