jQuery(document).ready(function ($) {
  $('.nav-open').on('click', function () {
    $('.nav-primary').addClass('open');
  });
  $('.nav-close').on('click', function () {
    $('.nav-primary').removeClass('open');
  });
  $('.nav-toggle').on('click', function () {
    $('.nav-toggle').toggleClass('open');
  });

  $('.nav-primary li').on('click', function () {
    $(this).toggleClass('open-submenu');
  });
});

jQuery(document).ready(function ($) {

  if ($('.req-checkox input:checkbox:checked').length == $('.req-checkox input:checkbox').length) {
    $('.form-builder-section-next').removeAttr('disabled');
  } else {
    $(".form-builder-section-next").attr("disabled", "disabled");
  }

  $('.req-checkox input:checkbox').on('click', function () {
    if ( $('.req-checkox input:checkbox:checked').length == $('.req-checkox input:checkbox').length ) {
      $('.form-builder-section-next').removeAttr('disabled');
    } else {
      $(".form-builder-section-next").attr("disabled", "disabled");
    }
    
  });


  $('.crime-type select').on('change', function () {

    var selectedVal = this.value;
    var found = crimeDefs.find(function (row) { return row.type == selectedVal; });
    console.log(this.value);
    console.log(found);

    if (found) {
      $(".crime-def p").html("Crime Definition: " + found.def);
      $(".crime-example p").html("Example: " +found.example);
    } else {
      $(".crime-def p").html("");
      $(".crime-example p").html("");
    }
   
  });



  
});


var crimeDefs =

  [
   {
      type: "Criminal Damage to Property Under $500",
      def: "When an unknown person changes, modifies or destroys public or private property.",
      example: "Incidents include throwing rocks through a window,knocking over a mailbox."
    },
    {
      type: "Criminal Damage to Vehicle Under $500",
      def: "When an unknown person tampers with or damages your motor vehicle.If your property is stolen from inside your car after it was damaged, you should make a theft report",
      example: "Incidents include keying the vehicle, broken windows or attempts to remove parts of the vehicle."
    },
    {
      type: "Criminal Defacement (Graffiti)",
      def: "When an unknown person marks personal or private property with graffiti with spray paint, etching tool or other writing instrument.",
      example: "Incidents include graffiti to your home or garage, etching on your windows, permanent marker on your flower pot."
    },
    {
      type: "Criminal Trespass to Vehicle",
      def: "The entering of an individual's vehicle by an offender where no other crime has been committed.",
      example: "Vehicle is found not in the manner left by the owner and it is clear someone was in the vehicle."
    },
    {
      type: "Financial Identity Theft $300 and Under",
      def: "When an unknown person uses any personal identifying information/ documents to obtatn credit, money, goods, services or other property. You should contact your credit cards, the below listed credit bureaus and other appropriate agencies immediately and provide thc report number you receive, Equifax: http://www.cqulfax.com,�' 1-800-525-6285 Experian: http: /fwww.expcrianxom/ 1 - 888 - 397 - 3742 TransUnion: http://www.transumon.com/ 1 - 800 - 680 - 7289 U.S.Federal Trade Commission: http://www.ftc.gov/ I -877-438-4338",
      example: "Incidents include using your credit card number to make purchases, obtaining utility service, obtaining a credit card in your name and making purchases, etc,"
    },
    {
      type: "Lost Property",
      def: "When property is missing or lost",
      example: "Property that is missing, leaving items in restaurant, or missing from home"
    },
    {
      type: "Other Offense - Harassment by Electronic Means",
      def: "An unknown person contacts you repeatedly or bullies you by email, social media, etc.",
      example: "An individual vou do not know sends you messages on Facebook, or an unknown person sends you multiple unwanted emails."
    },
    {
      type: "Other Offense - Harassment by Telephone",
      def: "An unknown person or entity repeatedly contacts your telephone number within short period of time.",
      example: "An individual calls you asking for someone who is not you and they keep calling back; an individual sends you repeated text messages."
    },
    {
      type: "Other Offense - Telephone Threats",
      def: "An unknown personcontacts you via the telephone and makes a threat to harm you.",
      example: "An individual calls you on the telephone and states they are going to beat you up."
    },
    {
      type: "Retail Theft",
      def: "When an unknown person steals inventory from a licensed business, Incidents are reported by the business owner or agent for the business. This does not include amounts disputed between the customer and the business.",
      example: "Any items stolen from the business."
    },
    {
      type: "Theft - $500 and Under",
      def: "When an unknown person takes your property without permission. This does not include someone physically taking something from your person by force.fear, assault, threats or use of a weapon.",
      example: "Any items that are stolen (except firearms). Incidents include failure to pay a cab fare or someone breaking into your vehicle and stealing your property."
    },
    {
      type: "Theft of Labor or Services",
      def: "The failure to pay for services rendered.",
      example: "The failure to pay cab fare, avoiding payment of the CTA fare(entering rear of door of bus, jumping turnstyle), using slugs in pay phones, tampering with utility service by disrupting the supply or removing devices for this purpose.",

    },
    
  ];